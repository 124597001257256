angular
    .module('annexaApp')
    .component('annexaReportQueryDefinition', {
        templateUrl: './components/common/annexa-report-query-definition/annexa-report-query-definition.html',
        controller:['RestService', 'CommonAdminService', 'Language', '$q', '$filter', 'CommonAdminModals', 'AnnexaFormlyFactory', 'ErrorFactory', '$rootScope', function (RestService, CommonAdminService, Language, $q, $filter, CommonAdminModals, AnnexaFormlyFactory, ErrorFactory, $rootScope) {
            var vm = this;

            vm.editedIndex = undefined;
            vm.addedQuery = undefined;

            vm.languageColumn = Language.getActiveColumn();

            var defaultAlias = function () {
                var defaultValue = 'c';
                if (vm.reportQueries && vm.reportQueries.length > 0) {
                    var index = 1;
                    var aliasNumber = $linq(vm.reportQueries).where(function(x){return (x.alias && x.alias.startsWith('c'));})
                        .select(function(x){
                            var aliasInt = x.alias.substring(1,x.alias.length);
                            if(Number.isNaN(parseInt(aliasInt))){
                                return -1;
                            } else {
                                return parseInt(aliasInt);
                            }
                        }).orderBy("x => x").toArray();
                    _.forEach(aliasNumber, function (value) {
                        if (value == index) {
                            index++;
                        }
                    });
                    defaultValue = 'c' + index;
                } else {
                    defaultValue = 'c1';
                }

                return defaultValue;
            };

            vm.addReportQuery = function(newQuery) {
                var toAddReportQuery = {
                    query: newQuery,
                    viewOrder: vm.reportQueries.length,
                    alias: defaultAlias()
                };

                if(vm.updateOnChange && vm.parentProperty && vm.parentId) {
                    toAddReportQuery[vm.parentProperty] = { id: vm.parentId };

                    vm.update(toAddReportQuery);
                }

                vm.reportQueries.push(toAddReportQuery);

                var indexAllQueries = $linq(vm.allQueries).indexOf("x => x.id == " + newQuery.id);

                if(indexAllQueries != -1) {
                    vm.allQueries.splice(indexAllQueries, 1);
                }

                vm.addedQuery = undefined;
            };

            vm.removeReportQuery = function(index) {
                var reportQueryToRemove = {};
                if(index >= 0 && vm.reportQueries){
                    reportQueryToRemove = vm.reportQueries[index];
                }
                if(vm.updateOnChange) {
                    RestService.delete('./api/report/report_query/' + vm.reportQueries[index].id)
                        .then(function (data) {
                            vm.reportQueries.splice(index, 1);
                            vm.addRemovedQueryToAllQueries(reportQueryToRemove);
                        }).catch(function (error) {
                            console.log(error);
                    });
                } else {
                    vm.reportQueries.splice(index, 1);
                    vm.addRemovedQueryToAllQueries(reportQueryToRemove);
                }
            };

            vm.addRemovedQueryToAllQueries = function(reportQueryToRemove){
                if(reportQueryToRemove && reportQueryToRemove.query){
                    vm.allQueries.push(reportQueryToRemove.query);
                    vm.allQueries = $linq(vm.allQueries).orderBy("x => x." + vm.languageColumn, linq.caseInsensitiveComparer).toArray();
                }
            };

            vm.sort = function(item, partFrom, partTo){
                if(partTo){
                    _.forEach(partTo, function(value, key){
                        value.viewOrder = key + 1;

                        vm.update(value);
                    });
                }
            };

            vm.updateReportQuery =  function(qpId){
                if(qpId) {
                    var qPs = $linq(vm.reportQueries).where("x => x.query && x.query.id == " + qpId).toArray();
                    if(qPs && qPs.length > 0 && qPs[0].alias){
                        vm.update(qPs[0]);
                    }
                }
            };

            vm.update = function(reportQuery) {
                if(vm.updateOnChange) {
                    if(reportQuery.id) {
                        RestService.update('./api/report/report_query/' + reportQuery.id, reportQuery)
                            .then(function (data) {
                                if(vm.parentAlerts) {
                                    vm.parentAlerts.length = 0;
                                }
                            }).catch(function (error) {
                                if(vm.parentAlerts) {
                                    vm.parentAlerts.length = 0;
                                    if (error && error.data)
                                        vm.parentAlerts.push({ msg: ErrorFactory.getErrorMessage('query', 'update', error.data) });
                                }
                        });
                    } else {
                        RestService.insert('./api/report/report_query', reportQuery)
                            .then(function (data) {
                                vm.reportQueries[vm.reportQueries.length - 1].id = data.id;
                                vm.reportQueries[vm.reportQueries.length - 1].query.id = data.query.id;
                            }).catch(function (error) {
                                console.error(error);
                        });
                    }
                }
            };

            this.$onInit = function() {
                if(!vm.reportQueries) {
                    vm.reportQueries = [];
                }

                vm.allQueries = [];

                RestService.findAll('Query')
                    .then(function(data) {
                         var responseQ = JSOG.decode(data.data);
                         
                         var queriesOK = [];
                         _.forEach($linq(responseQ).orderBy("x => x." + vm.languageColumn, linq.caseInsensitiveComparer).toArray(), function(item) {
                        	 queriesOK.push(item);
                         });

                         if(queriesOK && vm.reportQueries){
                        	 queriesOK = $linq(queriesOK).except(vm.reportQueries, function(x,y){
                                 if(x.query && x.query.id == y.id){
                                     return true;
                                 }
                                 return false;
                             }).toArray();
                             vm.allQueries.length = 0;
                             _.forEach(queriesOK, function(queryAux){
                          		vm.allQueries.push(queryAux);
                          	})                             
                         }
                    }).catch(function(error) {
                        console.error(error);
                });

            };
        }],
        bindings: {
            reportQueries: '=',
            updateOnChange: '=',
            parentId: '=',
            parentProperty: '@',
            parentAlerts: '=?'
        }
    })
;