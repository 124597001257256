angular
    .module('annexaApp')
    .component('annexaSelectAttendee', {
        templateUrl: './components/common/annexa-select-attendee/annexa-select-attendee.html',
        controller: ['$filter', '$rootScope', 'NotificationFactory', 'ThirdFactory', 'GlobalDataFactory', 'CommonService', 'Language', function($filter, $rootScope, NotificationFactory, ThirdFactory, GlobalDataFactory, CommonService, Language) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            if(!vm.title) {
                vm.title = 'global.literals.thirds';
            }
            
            _.forEach(vm.thirds, function (value, key) {
                var addressesSec = $linq(value.addresses).intersect(vm.addresses, "(x,y) => x.id == y.id").toArray();
                var addressSec
                if(addressesSec && addressesSec.length > 0){
                	addressSec = addressesSec[0];
                }
                vm.thirds[key].selected = false;
                if (vm.thirdsIdSelected) {
            		var thirdSelected = $linq(vm.thirdsIdSelected).where("x => x == " + vm.thirds[key].id).toArray();
            		if (thirdSelected.length > 0) {
            			vm.thirds[key].selected = true;
            		}
            	}
                vm.thirds[key].label = CommonService.getThirdCompleteName(value);
                vm.thirds[key].labelHtml = CommonService.getThirdRelationType(value);
                vm.thirds[key].labelAddress = addressSec ? CommonService.getThirdAddress(addressSec) : '';
                vm.thirds[key].addressesOrig = angular.copy(vm.thirds[key].addresses);
                if(addressSec && $rootScope.app.configuration && $rootScope.app.configuration.create_with_attendee_address && $rootScope.app.configuration.create_with_attendee_address.value == true) {
                    vm.thirds[key].addressesOrig = [addressSec];
                    _.forEach(vm.thirds[key].addressesOrig, function (address) {
                    	address.selected = true;
                    	address.labelAddress = CommonService.getThirdAddress(address);
                        address.notificationTypes = $linq(GlobalDataFactory.notificationTypes).where("x => x.addressType == '" + address.addressType + "'").toArray();
                        address.selectedNotificationType = address.notificationTypes.length != 0 ? address.notificationTypes[0] : undefined;
                    });
                } else {
                	_.forEach(vm.thirds[key].addressesOrig, function (address) {
                    	if(addressSec && addressSec.id == address.id){
                    		address.selected = true;
                    	}else{
                            address.selected = false;
                        }
                        address.labelAddress = CommonService.getThirdAddress(address);
                        address.notificationTypes = $linq(GlobalDataFactory.notificationTypes).where("x => x.addressType == '" + address.addressType + "'").toArray();
                        address.selectedNotificationType = address.notificationTypes.length != 0 ? address.notificationTypes[0] : undefined;
                    });
                }
                if (vm.removeAddressesWithoutSelectedNotificationType && vm.removeAddressesWithoutSelectedNotificationType == true) {
                	//No se muestran los canales cuyo tipo no tiene definido tipo de notificacion.
                	vm.thirds[key].addressesOrig = $linq(vm.thirds[key].addressesOrig).where("x => x.selectedNotificationType != undefined").toArray();
                }
            });

            vm.selectThird = function ($event, index) {
                var checkbox = $event.target;

                vm.thirds[index].selected = checkbox.checked;
            };

            vm.selectAddress = function (thirdId, addressId) {
                var third = $linq(vm.thirds).where("x => x.id =="+thirdId).toArray();
                if(third && third.length > 0){
                    _.forEach(third, function(value){
                        if(value.addressesOrig){
                            _.forEach(value.addressesOrig, function(value2){
                                if(value2.id == addressId){
                                    value2.selected = true;
                                }else{
                                    value2.selected = false;
                                }
                            });
                        }
                    });
                }
            };

        }],
        bindings: {
            title: '@?',
            thirds: '=',
            hideAddresses: '=',
            addresses: '=?',
            thirdsIdSelected: '=?',
            removeAddressesWithoutSelectedNotificationType: '='
        }
    })