angular
    .module('annexaApp')
    .component('annexaViewDossierTransaction', {
        templateUrl: './components/common/annexa-view-dossier-transaction/annexa-view-dossier-transaction.html',
        controller: ['$filter', '$rootScope','AnnexaPermissionsFactory', function($filter, $rootScope, AnnexaPermissionsFactory) {
            var vm = this;
            vm.dossiers = [];
            if(vm.dossierTransactions && vm.dossierTransactions.length > 0){
                vm.dossiers = $linq(vm.dossierTransactions).distinct("(x, y) => x.dossier.id == y.dossier.id").select("x => x.dossier").toArray();
            }
            vm.haveProfileDossier = function(dossier){
                var haveViewProfile = false;
                var haveProcessProfile = false;
                var isResponsibleUser = false;

                if(AnnexaPermissionsFactory.havePermission('view_dossier')) {
                    var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                    var dossierViewProfiles = $linq(dossier.procedure.procedureViewProfiles).select("x => x.profile.id").toArray();

                    var intersect = $linq(userProfiles).intersect(dossierViewProfiles, "(x,y) => x == y").toArray();

                    haveViewProfile = intersect.length > 0;
                }

                if(AnnexaPermissionsFactory.havePermission('process_dossier')) {
                    var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                    var dossierProcessProfiles = [];
                    if(dossier.dossierTransactions) {
                        _.forEach(dossier.dossierTransactions, function (dt) {
                            if(dt.processProfiles) {
                                _.forEach(dt.processProfiles, function (item) {
                                    dossierProcessProfiles.push(item.profile.id);
                                })
                            }
                        });
                    }
                    var intersect = $linq(userProfiles).intersect(dossierProcessProfiles, "(x,y) => x == y").toArray();

                    haveProcessProfile = intersect.length > 0;

                }
                
                if(dossier.responsibleUser.id == $rootScope.LoggedUser.id) {
                	isResponsibleUser = true;
                }

                return haveViewProfile || haveProcessProfile || isResponsibleUser;

            }

        }],
        bindings: {
            dossierTransactions: '=',
            startText: '@?',
            endText: '@?',
            class:'@?'
        }
    })