/**
 * Created by osirvent on 21/03/2017.
 */
angular
    .module('annexaApp')
    .component('annexaPageListFilter', {
        templateUrl: './components/common/annexa-page-list-filter/annexa-page-list-filter.html',
        require: {
            pageList: '^^annexaPageList'
        },
        controller: ['$rootScope', '$state', 'RouteStateFactory', '$scope',  function ($rootScope, $state, RouteStateFactory, $scope) {
            var vm = this;

            vm.showFilterBar = true;
            vm.routeLoaded = false;

            $scope.$on('tableDataInitComplete', function (event) {
                if(vm.routeLoaded) {
                    vm.submit();
                }
            });

            $scope.$on('tableDataRemoveFilterComplete', function (event) {
                RouteStateFactory.addRouteState($state.current, vm.filter);

                if(!vm.viewModes) {
                    vm.pageList.tableDefinition.reloadInternalData(true, true);
                } else if(vm.viewModes.showDefault) {
                    vm.pageList.tableDefinition.reloadInternalData(true, true);
                } else {
                    $rootScope.$broadcast('tableFilterSubmit');
                }
            });

            this.$onInit = function () {
                if(!vm.submit) {
                    vm.submit = function () {
                        RouteStateFactory.addRouteState($state.current, vm.filter);

                        if(!vm.viewModes) {
                            vm.pageList.tableDefinition.reloadInternalData(true, true);
                        } else if(vm.viewModes.showDefault) {
                            vm.pageList.tableDefinition.reloadInternalData(true, true);
                        } else {
                            $rootScope.$broadcast('tableFilterSubmit');
                        }
                    }
                }
                if(!vm.isBook){
	                var routeState = RouteStateFactory.getRouteState($state.current);
	                if(routeState) {
	                    vm.filter = routeState.state;
	                    vm.routeLoaded = true;
	                }
                }
            }
        }],
        bindings: {
            viewModes: '=',
            filter: '=?',
            submit: '&?',
            see: '=?',
            isBook: '=?'
        }
    });