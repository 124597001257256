angular
    .module('annexaApp')
    .component('annexaCustomFieldComponent', {
        templateUrl: './components/common/annexa-custom-field-component/annexa-custom-field-component.html',
        controller: ['$http', '$rootScope', function($http, $rootScope) {
            var vm = this;

            //region Datepicker

            vm.datepicker = {};

            vm.datepicker.opened = false;

            vm.datepicker.open = function ($event) {
                vm.datepicker.opened = !vm.datepicker.opened;
            };

            vm.datepickerOptions = {
                format: 'dd/MM/yyyy',
                initDate: new Date(),
                showWeeks: false,
                startingDay: 1
            }

            //endregion

            //region Number

            vm.step = 0;
            vm.actualModel = '';

            if(vm.frontendType == 'INPUT') {
                vm.inputTextType = 'text';

                if(!vm.minLength) {
                    vm.minLength = 0;
                }

                if(!vm.maxLength) {
                    vm.maxLength = 9999;
                }

                if(!vm.decimalPositions) {
                    vm.decimalPositions = 0;
                }

                if (vm.backendType == 'INTEGER') {
                    vm.inputTextType = 'number';
                    vm.step = 1;
                } else if (vm.backendType == 'DECIMAL') {
                    vm.inputTextType = 'number';
                    vm.step = '0.' + Array(vm.decimalPositions).join('0') + '1';
                }
            }

            //endregion

            //region Select

            vm.clear = function($event,model$select) {
                $event.stopPropagation();
                model = undefined;
                if($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
            }

            //endregion

            this.$onInit = function () {
                if(!vm.showFunctionParam){
                    vm.showFunction = function(){
                        return true;
                    }
                }else{
                    vm.showFunction = vm.showFunctionParam;
                }
                if(typeof vm.options === 'string') {
                    var url = vm.options;
                    vm.options = [];

                    $http({
                        url: url,
                        method: 'GET'
                    }).then(function(data) {
                        var decodedData = JSOG.decode(data.data);
                        vm.options = angular.fromJson(decodedData);
                    }).catch(function (error) {
                        vm.options = [];
                    });
                }
                if(vm.backendType  == 'DATETIME' && vm.model){
                    var dateParts = vm.model.split("/");
                    var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // month is 0-based
                    vm.actualModel = dateObject;
                }else  if (vm.frontendType == 'INPUT' && (vm.backendType == 'INTEGER')) {
                    vm.actualModel = parseInt(vm.model);
                } else if (vm.frontendType == 'INPUT' && vm.backendType == 'DECIMAL') {
                    if(vm.model){
                        var valueLiteral_parts = (vm.model).split('.');
                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
                            if(valueLiteral_parts[1]){
                                if(vm.decimalPositions){
                                    vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(vm.decimalPositions));
                                }else{
                                    vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                                }
                            }else{
                                if(vm.decimalPositions){
                                    vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(vm.decimalPositions));
                                }else {
                                    vm.actualModel =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                                }
                            }
                        }
                    }
                } else if(vm.frontendType == 'RADIO' || vm.frontendType == 'SELECT'){
                    vm.actualModel = parseInt(vm.model);
                }else if(vm.frontendType == 'CHECKBOX'){
                    vm.actualModel = [];
                    if(vm.options && vm.optionValueProp){
                        var modelsSelected = [];
                        if(vm.model){
                            modelsSelected = vm.model.split(',');
                        }
                        _.forEach(vm.options, function(option){
                            if(_.contains(modelsSelected, ""+option[vm.optionValueProp])){
                                vm.actualModel[option[vm.optionValueProp]] = true;
                            }else{
                                vm.actualModel[option[vm.optionValueProp]] = false;
                            }
                        });
                    }
                }else if(vm.frontendType == 'MULTIPLESELECT'){
                    vm.actualModel = [];
                    if(vm.options && vm.optionValueProp){
                        var modelsSelected = [];
                        if(vm.model){
                            modelsSelected = vm.model.split(',');
                        }
                        _.forEach(modelsSelected, function(option){
                            vm.actualModel.push(parseInt(option));
                        });
                    }
                }
            }

            this.$onChanges = function (changes) {
                if(changes.options) {
                    if(typeof vm.options === 'string') {
                        var url = vm.options;
                        vm.options = [];

                        $http({
                            url: url,
                            method: 'GET'
                        }).then(function(data) {
                            var decodedData = JSOG.decode(data.data);
                            vm.options = angular.fromJson(decodedData);
                        }).catch(function (error) {
                            vm.options = [];
                        });
                    }
                }
            }

            var previousModel;

            this.$doCheck = function() {
                if(!angular.equals(previousModel, this.model)) {
                    previousModel = this.model;

                    $rootScope.$broadcast('annexaCustomFieldComponentModelChanged', { key: this.key, model: this.model, plugin: this.plugin });
                }
            };

            vm.optionOnSelectAux = function(key, type){
                if(type){
                    if(type == 'DATETIME'){
                        if(vm.actualModel) {
                            var date = new Date(vm.actualModel);

                            var dd = date.getDate();
                            var mm = date.getMonth() + 1;

                            var yyyy = date.getFullYear();

                            if (dd < 10) {
                                dd = '0' + dd;
                            }

                            if (mm < 10) {
                                mm = '0' + mm;
                            }
                            vm.model = dd + '/' + mm + '/' + yyyy;
                        }else{
                            vm.model = '';
                        }
                        vm.optionOnSelect(key, vm.model);

                    }else if(type == 'number' || type == 'RADIO' || type == 'SELECT'){
                        if(vm.actualModel){
                            vm.model = vm.actualModel;
                        }else{
                            vm.model = '';
                        }
                        vm.optionOnSelect(key, vm.model);
                    }else if(type == 'CHECKBOX') {
                        vm.model = '';
                        _.forEach(vm.options, function (option, index) {
                            if (vm.actualModel[option[vm.optionValueProp]]) {
                                if (!vm.model) {
                                    vm.model += option[vm.optionValueProp];
                                } else {
                                    vm.model += "," + option[vm.optionValueProp];
                                }
                            }
                        });
                        vm.optionOnSelect(key, vm.model);
                    } else if(type == 'MULTIPLESELECT'){
                        vm.model = '';
                        if(vm.actualModel) {
                            _.forEach(vm.actualModel, function (option, index) {
                                if (!vm.model) {
                                    vm.model += option;
                                } else {
                                    vm.model += "," + option;
                                }
                            });
                        }
                        vm.optionOnSelect(key, vm.model);
                    }
                }
            }
        }],
        bindings: {
            key: '@',
            frontendType: '@',
            backendType: '@',
            label: '@',
            model: '=?',
            minLength: '=',
            maxLength: '=',
            decimalPositions: '=',
            required: '=',
            optionLabelProp: '@',
            optionValueProp: '@',
            options: '<',
            optionsInline: '=',
            optionOnSelect: '=',
            fc: '=',
            plugin: '<?',
            showFunctionParam: '='
        }
    })