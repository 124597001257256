angular
    .module('annexaApp')
    .factory('annexaFormFactory',['Language', '$rootScope', 'CommonService', function(Language, $rootScope, CommonService) {
        var factory = {};

        //region Generals

        var data = {
            row: true,
            colClass: 'col-sm-12',
            labelClass: 'label-strong',
            extralabel: true
        };

        var createField = function(field_key, field_type, field_class, field_templateOptions, field_data, field_hideExpression, field_defaultValue, field_controller, field_validators, field_watchers, customField, $rootScope, field_expressionProperties) {
            if (field_data) {
                field_data.informed = true;
                field_data.clear = function ($event, model, key, $select, to, options) {
                    $event.stopPropagation();
                    model[key] = undefined;
                    if ($select) {
                        $select.selected = undefined;
                        $select.search = undefined;
                    }

                    if (customField) {
                        if (customField.frontendType == 'SELECT') {
                            $rootScope.$broadcast('customFieldSelectSelected', {
                                customField: customField.id,
                                selectedValue: ''
                            });
                        }
                    }
                    if(to.onSelected) {
                        to.onSelected({});
                    }
                }
            } else {
                field_data = {
                    informed: false,
                    clear: function ($event, model, key, $select, to, options) {
                        $event.stopPropagation();
                        model[key] = undefined;
                        if ($select) {
                            $select.selected = undefined;
                            $select.search = undefined;
                        }
                        if(to.onSelected) {
                            to.onSelected({});
                        }
                    }
                };
            }

            var field = {
                key: field_key,
                type: field_type,
                className: field_class,
                data: field_data,
                templateOptions: field_templateOptions,
                validation: {
                    show: true
                },
                expressionProperties: {
                    "validation.show": "formControl.$submitted"
                },
                hideExpression: field_hideExpression
            };

            if (field_templateOptions) {
                if (field_type == 'annexaMultipleSelectRow' && field_templateOptions.required == true) {
                    field.validators = {
                        "required": {
                            "expression": function (viewValue, modelValue) {
                                if (modelValue && modelValue.length > 0) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        }
                    };
                    field.watcher = {
                        type: '$watchCollection',
                        expression: field_key,
                        listener: function (field, _new) {
                            if (field.formControl) {
                                field.formControl.$validate();
                            }
                        }
                    };
                } else {
                    if (field_templateOptions.validators) {
                        field.validators = field_templateOptions.validators;
                    }
                    if (field_templateOptions.watcher) {
                        field.watcher = field_templateOptions.watcher;
                    }
                }
            }

            if (field_defaultValue) {
                field.defaultValue = field_defaultValue;
            }

            if(field_controller) {
                field.controller = field_controller;
            }

            if(field_validators) {
                field.validators = field_validators;
            }

            if(field_watchers) {
                field.watcher = field_watchers;
            }

            if(field_expressionProperties) {
                field.expressionProperties = field_expressionProperties;
            }

            return field;
        }

        var selectTemplateOptions = function(label, valueProp, labelProp, options, required, type, onSelected, clearHide, tree, advancedSearch) {
            var to = {};

            to.type = type;
            to.optionsAttr = 'bs-options';
            to.ngOptions = 'option[to.valueProp] as option in to.options | filter: $select.search';
            to.label = label;
            to.valueProp = valueProp;
            to.labelProp = labelProp;
            to.placeholder = '';
            to.options = options;
            to.required = required || false;
            to.onSelected = onSelected || function ($item) {
            };
            to.clearHide = clearHide;

            if (tree && required) {
                to.validators = {
                    "required": {
                        "expression": function (viewValue, modelValue) {
                            return modelValue.hasOwnProperty('$selected')
                        }
                    }
                };

                to.watcher = {
                    type: '$watchCollection',
                    expression: 'model',
                    listener: function (field, _new) {
                        if (field.formControl) {
                            field.formControl.$validate();
                        }
                    }
                };

            }

            if(advancedSearch) {
                to.advancedSearch = advancedSearch;
            }

            return to;
        }

        var templateOptions = function (type, label, required, focus, step) {
            var to = {};

            to.type = type;
            to.label = label;
            to.required = required || false;
            to.focus = focus || false;
            to.step = step;
            to.validate = true;

            return to;
        }

        var languageTemplateOptions = function(type, label, required, disposition, modelField) {
            var to = {};

            to.id = 'language';
            to.type = type;
            to.label = label;
            to.required = required || false;
            to.disposition = disposition || 'horitzontal21';
            to.modelField = modelField || '';

            return to;
        }

        var datepickerTemplateOptions = function (type, label, required, datepickerOptions) {
            var to = {};

            to.type = type;
            to.label = label;
            to.required = required || false;
            to.validate = true;
            to.datepickerOptions =  datepickerOptions;

            return to;
        }

        var textareaTemplateOptions = function(label, rows, required, options, maxLength) {
            var to = {};

            to.label = label;
            to.rows = rows || 5;
            to.required = required || false;
            to.options = options;
            to.maxLength = maxLength;

            return to;
        }

        var typeaheadTemplateOptions = function(label, search, placeholder, required) {
            var to = {};

            to.label = label;
            to.required = required || false;
            to.placeholder = placeholder;
            to.search = search;

            return to;
        }

        var componentTemplateOptions = function (type, aditional) {
            var to = aditional ? aditional : {};

            to.type = type;

            return to
        }

        //endregion

        //region PredefinedFields

        var getProfileField = function (fieldDefinition) {
            var getDefaultProfileEntryType = function () {
                switch (fieldDefinition.origin) {
                    case 'RegisterEntryInput':
                        return 'REG';
                }

                return 'UNKNOWN';
            }

            var dataType = undefined;

            if(typeof fieldDefinition.data === "string" || fieldDefinition.data instanceof String) {
                dataType = fieldDefinition.data;
            } else if (Array.isArray(fieldDefinition.data) && fieldDefinition.data.length > 0 && _.contains(Object.hasOwnProperty(fieldDefinition.data[0]), 'id') && _.contains(Object.hasOwnProperty(fieldDefinition.data[0]), Language.getActiveColumn())) {
                dataType = 'Array';
            }

            var profiles = [];
            var defaultProfile = undefined;

            switch (dataType) {
                case 'Array':
                    profiles = fieldDefinition.data;
                    break;
                case 'loggedUser':
                    profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                    defaultProfile = $linq($rootScope.LoggedUser.userDefaultEntityProfiles).singleOrDefault(undefined, "x => x.entityType == '" + getDefaultProfileEntryType() + "'");
                    break;
            }

            if(profiles.length > 1) {
                if(defaultProfile && defaultProfile.profile && defaultProfile.profile.id){
                    fieldDefinition.defaultValue = defaultProfile.profile.id;
                }
                return createField(
                    fieldDefinition.id,
                    'annexaSelectRow',
                    fieldDefinition.colClass,
                    selectTemplateOptions(
                        'global.literals.profile',
                        'id',
                        Language.getActiveColumn(),
                        profiles,
                        fieldDefinition.required
                    ),
                    data,
                    !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                    fieldDefinition.defaultValue,
                    fieldDefinition.controller,
                    fieldDefinition.validators,
                    fieldDefinition.watchers,
                    undefined,
                    undefined,
                    fieldDefinition.expressionProperties
                );
            } else {
                var profileValue = ((defaultProfile) ? defaultProfile.profile : ((profiles.length > 0) ? profiles[0] : undefined));

                if(profileValue) {
                    profileValue.userProfiles = undefined;
                }

                return createField(
                    fieldDefinition.id,
                    'annexaHidden',
                    fieldDefinition.colClass,
                    templateOptions(
                        'hidden',
                        '',
                        fieldDefinition.required
                    ),
                    data,
                    undefined,
                    profileValue,
                    fieldDefinition.controller,
                    fieldDefinition.validators,
                    fieldDefinition.watchers,
                    undefined,
                    undefined,
                    fieldDefinition.expressionProperties
                );
            }
        }

        var getSectionField = function (fieldDefinition) {
            var dataType = undefined;

            if(typeof fieldDefinition.data === "string" || fieldDefinition.data instanceof String) {
                dataType = fieldDefinition.data;
            } else if (Array.isArray(fieldDefinition.data) && fieldDefinition.data.length > 0 && _.contains(Object.hasOwnProperty(fieldDefinition.data[0]), 'id') && _.contains(Object.hasOwnProperty(fieldDefinition.data[0]), Language.getActiveColumn())) {
                dataType = 'Array';
            }

            var sections = [];

            switch (dataType) {
                case 'Array':
                    sections = fieldDefinition.data;
                    break;
                case 'loggedUser':
                    sections = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                    break;
            }

            if(sections.length > 1) {
                return createField(
                    fieldDefinition.id,
                    'annexaSelectRow',
                    fieldDefinition.colClass,
                    selectTemplateOptions(
                        'global.literals.section',
                        'id',
                        Language.getActiveColumn(),
                        sections,
                        fieldDefinition.required
                    ),
                    data,
                    !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                    fieldDefinition.defaultValue,
                    fieldDefinition.controller,
                    fieldDefinition.validators,
                    fieldDefinition.watchers,
                    undefined,
                    undefined,
                    fieldDefinition.expressionProperties
                );
            } else {
                var sectionValue = sections.length == 1 ? sections[0] : undefined;

                return createField(
                    fieldDefinition.id,
                    'annexaHidden',
                    fieldDefinition.colClass,
                    templateOptions(
                        'hidden',
                        '',
                        fieldDefinition.required
                    ),
                    data,
                    undefined,
                    sectionValue,
                    fieldDefinition.controller,
                    fieldDefinition.validators,
                    fieldDefinition.watchers,
                    undefined,
                    undefined,
                    fieldDefinition.expressionProperties
                );
            }

        }

        var getUserField = function(fieldDefinition) {
            return createField(
                fieldDefinition.id,
                'annexaTypeaheadRow',
                fieldDefinition.colClass,
                typeaheadTemplateOptions(
                    fieldDefinition.label,
                    CommonService.loadUsers,
                    'global.literals.user',
                    fieldDefinition.required
                ),
                data,
                !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                fieldDefinition.defaultValue,
                fieldDefinition.controller,
                fieldDefinition.validators,
                fieldDefinition.watchers,
                undefined,
                undefined,
                fieldDefinition.expressionProperties
            )
        }

        var getPredefinedField = function(fieldDefinition) {
            switch (fieldDefinition.predefined) {
                case 'profile':
                    return getProfileField(fieldDefinition);
                case 'section':
                    return getSectionField(fieldDefinition);
                case 'user':
                    return getUserField(fieldDefinition);
            }

            return undefined;
        }

        //endregion

        //region Field

        var getDateField = function (fieldDefinition) {
            return createField(
                fieldDefinition.id,
                'annexaDatepickerRow',
                fieldDefinition.colClass,
                datepickerTemplateOptions(
                    'text',
                    fieldDefinition.label,
                    fieldDefinition.required,
                    {
                        format: 'dd/MM/yyyy',
                        initDate: new Date(),
                        showWeeks: false,
                        startingDay: 1
                    }
                ),
                data,
                !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                fieldDefinition.defaultValue,
                fieldDefinition.controller,
                fieldDefinition.validators,
                fieldDefinition.watchers,
                undefined,
                undefined,
                fieldDefinition.expressionProperties
            )
        }

        var getTimeField = function (fieldDefinition) {
            return createField(
                fieldDefinition.id,
                'annexaTimepickerRow',
                fieldDefinition.colClass,
                datepickerTemplateOptions(
                    'text',
                    fieldDefinition.label,
                    fieldDefinition.required
                ),
                data,
                !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                fieldDefinition.defaultValue,
                fieldDefinition.controller,
                fieldDefinition.validators,
                fieldDefinition.watchers,
                undefined,
                undefined,
                fieldDefinition.expressionProperties
            )
        }

        var getSelectField = function (fieldDefinition, multiple, html, advancedSearch) {
            return createField(
                fieldDefinition.id,
                advancedSearch ? 'annexaSelectSearchButtonRow' : (!multiple ? 'annexaSelectRow' : (!html ? 'annexaMultipleSelectRow' : 'annexaMultipleSelectRowHtml')),
                fieldDefinition.colClass,
                selectTemplateOptions(
                    fieldDefinition.label,
                    fieldDefinition.valueProp ? fieldDefinition.valueProp : 'id',
                    fieldDefinition.labelProp ? fieldDefinition.labelProp : Language.getActiveColumn(),
                    fieldDefinition.data,
                    fieldDefinition.required,
                    undefined,
                    fieldDefinition.onSelected,
                    undefined,
                    undefined,
                    advancedSearch
                ),
                data,
                !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                fieldDefinition.defaultValue,
                fieldDefinition.controller,
                fieldDefinition.validators,
                fieldDefinition.watchers,
                undefined,
                undefined,
                fieldDefinition.expressionProperties
            )
        }

        var getSelectTreeField = function(fieldDefinition) {
            return createField(
                fieldDefinition.id,
                'annexaSelectTreeRow',
                fieldDefinition.colClass,
                selectTemplateOptions(
                    fieldDefinition.label,
                    undefined,
                    undefined,
                    fieldDefinition.data,
                    fieldDefinition.required,
                    undefined,
                    fieldDefinition.onSelected,
                    undefined,
                    true
                ),
                data,
                !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                fieldDefinition.defaultValue,
                fieldDefinition.controller,
                fieldDefinition.validators,
                fieldDefinition.watchers,
                undefined,
                undefined,
                fieldDefinition.expressionProperties
            );
        }

        var getTextAreaField = function(fieldDefinition) {
            return createField(
                fieldDefinition.id,
                'annexaTextAreaRow',
                fieldDefinition.colClass,
                textareaTemplateOptions(
                    fieldDefinition.label,
                    fieldDefinition.rows ? fieldDefinition.rows : 5,
                    fieldDefinition.required,
                    undefined,
                    fieldDefinition.maxLength
                ),
                data, 
                !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                fieldDefinition.defaultValue,
                fieldDefinition.controller,
                fieldDefinition.validators,
                fieldDefinition.watchers,
                undefined,
                undefined,
                fieldDefinition.expressionProperties
            )
        }

        var getRadioCheckboxField = function (fieldDefinition) {
            return createField(
                fieldDefinition.id,
                'annexaRadioCheckboxRow',
                fieldDefinition.colClass,
                selectTemplateOptions(
                    fieldDefinition.label,
                    fieldDefinition.valueProp ? fieldDefinition.valueProp : 'value',
                    fieldDefinition.labeProp ? fieldDefinition.labeProp : 'label',
                    fieldDefinition.data,
                    fieldDefinition.required,
                    fieldDefinition.isRadio ? 'radio' : 'checkbox'
                ),
                data,
                !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                fieldDefinition.defaultValue,
                fieldDefinition.controller,
                fieldDefinition.validators,
                fieldDefinition.watchers,
                undefined,
                undefined,
                fieldDefinition.expressionProperties
            )
        }

        var getTextField = function (fieldDefinition) {
            return createField(
                fieldDefinition.id,
                'annexaInputRow',
                fieldDefinition.colClass,
                templateOptions(
                    'text',
                    fieldDefinition.label,
                    fieldDefinition.required
                ),
                data,
                !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                fieldDefinition.defaultValue,
                fieldDefinition.controller,
                fieldDefinition.validators,
                fieldDefinition.watchers,
                undefined,
                undefined,
                fieldDefinition.expressionProperties
            )
        }

        var getLanguageField = function (fieldDefinition) {
            return createField(
                fieldDefinition.id,
                'annexaInputLanguage',
                fieldDefinition.colClass,
                languageTemplateOptions(
                    'text',
                    fieldDefinition.label,
                    fieldDefinition.required
                ),
                data,
                !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                fieldDefinition.defaultValue,
                ['$scope', '$rootScope', function($scope, $rootScope) { $scope.languages = $rootScope.app.languagedef; }],
                {
                    "required": {
                        "expression": function (viewValue, modelValue, scope) {
                            var valid = true;
                            if (languages) {
                                angular.forEach(languages, function (value2, key2) {
                                    if (value2 && value2.column) {
                                        if (modelValue && !modelValue[value2.column]) {
                                            valid = false;
                                        }
                                    }
                                });
                            }
                            return valid;
                        }
                    }
                },
                {
                    type: '$watchCollection',
                    expression: 'model',
                    listener: function(field, _new) {
                        if(field.formControl){
                            field.formControl.$validate();
                        }
                    }
                },
                undefined,
                undefined,
                fieldDefinition.expressionProperties
            );
        }

        var getCheckboxField = function (fieldDefinition) {
            return createField(
                fieldDefinition.id,
                'annexaCheckbox',
                fieldDefinition.className,
                templateOptions(
                    'checkbox',
                    fieldDefinition.label,
                    fieldDefinition.required
                ),
                data,
                !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                fieldDefinition.defaultValue,
                fieldDefinition.controller,
                fieldDefinition.validators,
                fieldDefinition.watchers,
                undefined,
                undefined,
                fieldDefinition.expressionProperties
            )
        }
        
        var getLabelField = function (fieldDefinition) {
            return createField(fieldDefinition.id,
            	'annexaLabel',
            	fieldDefinition.className,
            	templateOptions(
            			undefined,
            			fieldDefinition.label,
            			fieldDefinition.required,
            			fieldDefinition.focus,
            			undefined
            	),
            	data
            )
        }
        
        var getHiddenField = function (fieldDefinition) {
        	return createField(
				fieldDefinition.id,
				'annexaHidden',
				fieldDefinition.colClass,
				templateOptions(
				    'hidden',
				    '',
				     fieldDefinition.required
				),
				data,
				undefined,
				fieldDefinition.defaultValue,
				fieldDefinition.controller,
				fieldDefinition.validators,
				fieldDefinition.watchers,
				undefined,
				undefined,
				fieldDefinition.expressionProperties
			)
        }

        var getComponentField = function(fieldDefinition, model) {
            if(!fieldDefinition.data) {
                fieldDefinition.data = angular.copy(data);
            }

            fieldDefinition.data.model = model;

            return createField(
                fieldDefinition.id,
                'annexaComponent',
                fieldDefinition.colClass,
                componentTemplateOptions(
                    fieldDefinition.componentType,
                    fieldDefinition.componentTemplateOptions
                ),
                fieldDefinition.data,
                !fieldDefinition.remove ? fieldDefinition.hideExpression : function() { return true },
                fieldDefinition.defaultValue,
                fieldDefinition.controller,
                fieldDefinition.validators,
                fieldDefinition.watchers,
                undefined,
                undefined,
                fieldDefinition.expressionProperties
            );
        }
        
        var getFiedTypeField = function(fieldDefinition, model) {
            switch (fieldDefinition.fieldType) {
                case 'date':
                    return getDateField(fieldDefinition);
                case 'time':
                    return getTimeField(fieldDefinition);
                case 'select':
                    return getSelectField(fieldDefinition);
                case 'select_multiple':
                    return getSelectField(fieldDefinition, true);
                case 'select_multiple_html':
                    return getSelectField(fieldDefinition, true, true);
                case 'select_advanced_search':
                    return getSelectField(fieldDefinition, false, false, fieldDefinition.advancedSearch);
                case 'select_tree':
                    return getSelectTreeField(fieldDefinition);
                case 'textarea':
                    return getTextAreaField(fieldDefinition);
                case 'radio_checkbox':
                    return getRadioCheckboxField(fieldDefinition);
                case 'text':
                    return getTextField(fieldDefinition);
                case 'language':
                    return getLanguageField(fieldDefinition);
                case 'component':
                    return getComponentField(fieldDefinition, model);
                case 'checkbox':
                    return getCheckboxField(fieldDefinition);
                case 'label':
                    return getLabelField(fieldDefinition);    
                case 'hidden':
                    return getHiddenField(fieldDefinition);    
            }

            return undefined;
        }

        //endregion

        factory.getField = function (fieldDefinition, model) {
            if (!fieldDefinition.removeOnEset || (fieldDefinition.removeOnEset && !$rootScope.esetMode)) {
                if (!fieldDefinition.hasOwnProperty('hideExpression')) {
                    fieldDefinition.hideExpression = undefined;
                }

                if (!fieldDefinition.hasOwnProperty('defaultValue')) {
                    fieldDefinition.defaultValue = undefined;
                }

                switch (fieldDefinition.type) {
                    case 'predefined':
                        return getPredefinedField(fieldDefinition);
                    case 'field':
                        return getFiedTypeField(fieldDefinition, model);
                }
            }

            return undefined;
        }

        return factory;
    }])
    .component('annexaForm',{
        templateUrl: './components/common/annexa-form/annexa-form.html',
        controller:['annexaFormFactory', '$scope', '$timeout', function (annexaFormFactory, $scope, $timeout) {
            var vm = this;

            vm.fields = [];
            vm.model = {};

            this.$onInit = function() {
                _.forEach(vm.fieldsDefinition, function(item) {
                    var field = annexaFormFactory.getField(item, vm.model);
                    if(item.model) {
                        vm.model[item.id] = item.model;
                    }

                    if(field) {
                        vm.fields.push(field);
                    }
                });

                if(!vm.options) {
                    vm.options = { watchAllExpressions: true, formState: { readOnly: false } };
                } else {
                    if(!vm.options.hasOwnProperty('formState')) {
                        vm.options.formState = { readOnly: false };
                    }
                }
            }

            $scope.$on('submitForm', function (event, args) {
                if(args.id && args.id == vm.id) {
                    $timeout(function () {
                        angular.element('#annexaForm-' + vm.id).trigger('click');
                    })
                }
            })
        }],
        bindings: {
            id: '@',
            submit: '=',
            fieldsDefinition: '=',
            options: '=?'
        }
    })