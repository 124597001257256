angular
    .module('annexaApp')
    .component('annexaBoxTask', {
        templateUrl: './components/common/annexa-box-task/annexa-box-task.html',
        controller: ['AnnexaObjectBoxFactory', 'AnnexaEntityFactory', 'globalModals', 'ModalService', '$rootScope', 'GlobalDataFactory', function (AnnexaObjectBoxFactory, AnnexaEntityFactory, globalModals, ModalService, $rootScope, GlobalDataFactory) {
            var vm = this;

            this.$onInit = function() {
                vm.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(vm.permissions, 'create_task', 'create_task', vm.isEdit);

                vm.taskModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tasks'").toArray();

                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.tasks';
                }

                if(vm.search) {
                    vm.search.origin = 'common';
                    vm.search.type = 'Task';
                    vm.search.customFilter = '{ "description": "@VAL@"}';
                    vm.search.additionalFilter = { assignedRegisterEntry:true, finished: false }
                    vm.search.processSearch = function (object) {
                        var assigned = '';

                        if(object.user) {
                            assigned += object.user.name + ' ' + object.user.surename1 + (object.user.surename2 ? ' ' + object.user.surename2 : '') ;
                        }

                        return object.description + (assigned ? ' (' + assigned + ')' : '');
                    }

                    if(!vm.search.completeAdd) {
                        vm.search.completeAdd = function() {
                            var newContent = JSOG.decode(vm.search.searchedValue.object);

                            vm.content.push(newContent);
                            vm.search.searchedValue = { id: -1, value: '' };
                            if(vm.search.saveSearch) {
                                vm.search.saveSearch(newContent);
                            }
                        }
                    }
                }

                if(vm.new) {
                    vm.new.newFunc = function() {
                        //region Això s'haurà de canviar quan arreglem tasques

                        var proccesNew = function(item, form) {
                            vm.search.searchedValue = { id: item.id, object: item }
                            $rootScope.$broadcast('annexaObjectBoxSearchAdd');
                            form.close();
                        }

                        var modal = angular.copy(globalModals.receiverNew);
                        modal.title = 'global.literals.new_task_title';
                        modal.size = 'modal-lg'

                        var entity = new AnnexaEntity(new Date().getTime() + '', 'modal', AnnexaEntityFactory.getTaskBoxNewForm(modal, proccesNew), AnnexaEntityFactory.taskBoxes());

                        modal.annexaFormly = entity.form;
                        modal.boxes = entity.boxes;
                        modal.submitModal = modal.annexaFormly.onSubmit;
                        modal.alerts = [];
                        entity.modal = modal;

                        ModalService.showModal({
                            template: '<annexa-entity id="' + entity.id + '" entity-definition="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                            controller: 'AnnexaFormlyModalController',
                            modalObj: entity
                        }).then(function(modalThen) {
                            modal.modal = modalThen;
                            modal.close = modalThen.element.scope().close;
                            modal.annexaFormly.close = modalThen.element.scope().close;
                            modalThen.element.modal();
                            angular.element(".modal-title").focus();
                            /*if(pluginId && pluginObject){
                                AnnexaPlugins.addPlugin(modalThen.element.scope(), pluginObject, pluginId);
                            }*/
                        });

                        //endregion
                    }
                }
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            new: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            config: '=',
            origin: '@'
        }
    })
    .component('annexaBoxTaskRender', {
        templateUrl: './components/common/annexa-box-task/annexa-box-task-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['DialogsFactory', '$rootScope', 'HelperService', function(DialogsFactory, $rootScope, HelperService) {
            var vm = this;

            var getMaxDays = function(task){
                var holidays = [];
                var workinDays = 0;

                if($rootScope && $rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays){
                    holidays = $rootScope.LoggedUser.entity.holidays;
                }

                workinDays = HelperService.getWorkingDays(new Date(task.createdDate), new Date(), holidays);

                return task.days - workinDays;
            }

            vm.getClass = function(task) {
                return HelperService.getWorkingDaysClass(getMaxDays(task));
            }

            vm.getLabel = function(task) {
                var maxDays = getMaxDays(task);

                if(maxDays > 5) {
                    return '+' + maxDays;
                } else {
                    return maxDays;
                }
            }

            vm.delete = function(index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteTaskBox')
                    .then(function (data) {
                        var removedId = vm.content[index].id;

                        vm.content.splice(index, 1);

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxTasksDelete', { removedId: removedId, origin: vm.origin});
                        }
                    }).catch(function (data) {
                        //Empty
                });
            }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    })