angular
    .module('annexaApp')
    .factory('annexaDashboardFactory',['$q', '$http', 'GlobalDataFactory', '$filter', 'Language', function($q, $http, GlobalDataFactory, $filter, Language) {
        var factory = {};

        factory.documentCounters = [];
        factory.tramCounters = [];
        factory.regCounters = [];
        factory.taskFullCounters = [];
        factory.taskSimpleCounters = [];

        factory.loadDashboard = function() {
            factory.documentCounters = [];
            factory.tramCounters = [];
            factory.regCounters = [];
            factory.taskFullCounters = [];
            factory.taskSimpleCounters = [];

            var promises = [];

            promises.push($http({ method: 'GET', url: './api/doc/PENDING_SIGN/status/count' }));
            promises.push($http({ method: 'GET', url: './api/tram/counters' }));
            promises.push($http({ method: 'GET', url: './api/reg/dashboard/counters' }));
            promises.push($http({ method: 'GET', url: './api/tasks/dashboard/counters/full' }));

            return $q.all(promises)
                .then(function(data) {

                    //region Documents

                    var docStatuses = $linq(GlobalDataFactory.documentStatuses).where("x => x.id != ''").orderBy("x => x.orderStatus").toArray();

                    _.forEach(docStatuses, function(item) {
                        factory.documentCounters.push({
                            state: 'annexa.doc.documents.all',
                            stateParams: { status: item.id },
                            icon: item.icon,
                            style: item.style,
                            title: 'global.documentState.' + item.id,
                            number: item.id != 'PENDING_SIGN' ? '' : (data[0].data ? data[0].data : '')
                        })
                    })

                    //endregion

                    //region Tram

                    var countersTram = data[1].data;

                    if(countersTram.hasOwnProperty("PendingTramitation")) {
                        factory.tramCounters.push({
                            state: 'annexa.tram.pending',
                            stateParams: undefined,
                            title: 'global.literals.tramPendingTramitation',
                            number: countersTram.PendingTramitation > 0 ? countersTram.PendingTramitation : ''
                        });
                    }

                    if(countersTram.hasOwnProperty("Expired")) {
                        factory.tramCounters.push({
                            state: 'annexa.tram.pending',
                            stateParams: { type: 'expired' },
                            title: 'global.literals.outOfTime',
                            number: countersTram.Expired > 0 ? countersTram.Expired : ''
                        });
                    }

                    if(countersTram.hasOwnProperty("PendingSee")) {
                        factory.tramCounters.push({
                            state: 'annexa.tram.view',
                            stateParams: { type: 'tramitation' },
                            title: 'global.literals.tramPendingSee',
                            number: countersTram.PendingSee > 0 ? countersTram.PendingSee : ''
                        });
                    }

                    if(countersTram.hasOwnProperty("Finally")) {
                        factory.tramCounters.push({
                            state: 'annexa.tram.view',
                            stateParams: { type: 'finally' },
                            title: 'global.literals.tramFinally',
                            number: countersTram.Finally > 0 ? countersTram.Finally : ''
                        });
                    }

                    if(countersTram.hasOwnProperty("Favorite")) {
                        factory.tramCounters.push({
                            state: 'annexa.tram.pending',
                            stateParams: { type: 'favorit' },
                            title: 'global.literals.tramFavorite',
                            number: countersTram.Favorite > 0 ? countersTram.Favorite : ''
                        });
                    }

                    if(countersTram.hasOwnProperty("Participated")) {
                        factory.tramCounters.push({
                            state: 'annexa.tram.view',
                            stateParams: { type: 'participated' },
                            title: 'global.literals.tramParticipated',
                            number: countersTram.Participated > 0 ? countersTram.Participated : ''
                        });
                    }

                    //endregion

                    //region Registre

                    _.forOwn(data[2].data, function(item, key) {
                        if(key.startsWith('DiligenceType_')) {
                            var idDiligenceType = key.replace('DiligenceType_', '');
                            var diligenceType = $linq(GlobalDataFactory.registerDiligenceTypes).singleOrDefault(undefined, "x => x.id == " + idDiligenceType);

                            if(diligenceType) {
                                factory.regCounters.push({
                                    permissions: ['view_input_register', 'diligence_input_register','view_all_diligences','view_all_input_register'],
                                    state: 'annexa.reg.input.diligences',
                                    stateParams: { diligenceType: diligenceType.id, diligenceStatus: ((diligenceType.canAppendDossier || diligenceType.canStartDossier)?'PENDING_ACCEPT':'PENDING_RECEPT') },
                                    title: $filter('translate')('global.literals.dashboardDiligenceType', { diligenceType: diligenceType[Language.getActiveColumn()]}),
                                    number: item ? item : ''
                                });
                            }
                        }
                    })

                    //endregion

                    //region Tasques

                    var total = 0;

                    if (data[3].data.hasOwnProperty('AssignedNoExpired')) {
                        var item = {
                            state: 'annexa.tasks.assignedTasks',
                            stateParams: {type: 'ANE'},
                            title: 'global.literals.withinDeadline',
                            column: 1,
                            number: data[3].data['AssignedNoExpired'] ? data[3].data['AssignedNoExpired'] : '',
                            isChild: true
                        };

                        factory.taskFullCounters.push(item);
                        factory.taskSimpleCounters.push(item);

                        total += data[3].data['AssignedNoExpired'];
                    }

                    if (data[3].data.hasOwnProperty('AssignedExpired')) {
                        var item = {
                            state: 'annexa.tasks.assignedTasks',
                            stateParams: {type: 'AE'},
                            title: 'global.literals.outOfTime',
                            column: 1,
                            number: data[3].data['AssignedExpired'] ? data[3].data['AssignedExpired'] : '',
                            isChild: true
                        };

                        factory.taskFullCounters.push(item);
                        factory.taskSimpleCounters.push(item);

                        total += data[3].data['AssignedExpired'];
                    }

                    if (data[3].data.hasOwnProperty('AssignedPending')) {
                        var item = {
                            state: 'annexa.tasks.assignedTasks',
                            stateParams: {type: 'AP'},
                            title: 'global.task.pendingTasks',
                            column: 1,
                            number: data[3].data['AssignedPending'] ? data[3].data['AssignedPending'] : '',
                            isChild: true
                        };

                        factory.taskFullCounters.push(item);
                        factory.taskSimpleCounters.push(item);

                        total += data[3].data['AssignedPending'];
                    }

                    var item = {
                        state: 'annexa.tasks.assignedTasks',
                        stateParams: {type: 'A'},
                        title: 'global.task.myTask',
                        column: 1,
                        number: total ? total : '',
                        isGroup: true
                    };

                    factory.taskFullCounters.unshift(item);
                    factory.taskSimpleCounters.unshift(item);

                    var secondTotalPos = factory.taskFullCounters.length;
                    total = 0;

                    if (data[3].data.hasOwnProperty('CreatedNoExpired')) {
                        var item ={
                            state: 'annexa.tasks.createdTasks',
                            stateParams: {type: 'CNE'},
                            title: 'global.literals.withinDeadline',
                            column: 2,
                            number: data[3].data['CreatedNoExpired'] ? data[3].data['CreatedNoExpired'] : '',
                            isChild: true
                        };

                        factory.taskFullCounters.push(item);

                        total += data[3].data['CreatedNoExpired'];
                    }

                    if (data[3].data.hasOwnProperty('CreatedExpired')) {
                        var item = {
                            state: 'annexa.tasks.createdTasks',
                            stateParams: {type: 'CE'},
                            title: 'global.literals.outOfTime',
                            column: 2,
                            number: data[3].data['CreatedExpired'] ? data[3].data['CreatedExpired'] : '',
                            isChild: true
                        };

                        factory.taskFullCounters.push(item);

                        total += data[3].data['CreatedExpired'];
                    }

                    if (data[3].data.hasOwnProperty('CreatedPending')) {
                        var item = {
                            state: 'annexa.tasks.createdTasks',
                            stateParams: {type: 'CP'},
                            title: 'global.task.pendingTasks',
                            column: 2,
                            number: data[3].data['CreatedPending'] ? data[3].data['CreatedPending'] : '',
                            isChild: true
                        };

                        factory.taskFullCounters.push(item);

                        total += data[3].data['CreatedPending'];
                    }

                    if (data[3].data.hasOwnProperty('CreatedFinished')) {
                        var item = {
                            state: 'annexa.tasks.createdTasks',
                            stateParams: {type: 'CF'},
                            title: 'global.task.finalizedTasks',
                            column: 2,
                            number: data[3].data['CreatedFinished'] ? data[3].data['CreatedFinished'] : '',
                            isChild: true
                        };

                        factory.taskFullCounters.push(item);

                        total += data[3].data['CreatedFinished'];
                    }

                    var item = {
                        state: 'annexa.tasks.createdTasks',
                        stateParams: {type: 'C'},
                        title: 'global.task.createdTask',
                        column: 2,
                        number: total ? total : '',
                        isGroup: true
                    };

                    factory.taskFullCounters.splice(secondTotalPos, 0, item);

                    //endregion
                }).catch(function(error) {
                    console.error(error);

                    factory.documentCounters = [];
                    factory.tramCounters = [];
                    factory.regCounters = [];
                    factory.taskFullCounters = [];
                    factory.taskSimpleCounters = [];
                });
        }

        return factory;
    }])
    .component('annexaDashboard', {
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard.html',
        controller:['annexaDashboardFactory', 'GlobalDataFactory', function(annexaDashboardFactory, GlobalDataFactory) {
            var vm = this;

            vm.documentCounters = annexaDashboardFactory.documentCounters
            vm.tramCounters = annexaDashboardFactory.tramCounters;
            vm.regCounters = annexaDashboardFactory.regCounters;
            vm.taskFullCounters = annexaDashboardFactory.taskFullCounters;
            vm.taskSimpleCounters = annexaDashboardFactory.taskSimpleCounters;

            vm.docModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.doc.documents.all'");
            vm.signModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.sign'");
            vm.tramModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.tram'");
            vm.regModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.reg.input'");
            vm.taskModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.tasks'");
        }]
    })
    .component('annexaDashboardBox', {
        transclude: true,
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-box.html',
        controller: [function() {
            var vm = this;

            vm.columnOneCounters = [];
            vm.columnTwoCounters = []

            this.$onInit = function () {
                if(vm.twoColumns) {
                    vm.columnOneCounters = $linq(vm.counters).where("x => x.column == 1").toArray();
                    vm.columnTwoCounters = $linq(vm.counters).where("x => x.column == 2").toArray();
                }
            }
        }],
        bindings: {
            headState: '@',
            headParameters: '=?',
            iconType: '@',
            icon: '@',
            title: '@',
            counters: '=',
            twoColumns: '='
        }
    })
    .component('annexaDashboardBoxContent', {
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-box-content.html',
        controller: [function() {
            var vm = this;
        }],
        bindings: {
            counters: '='
        }
    })
    .component('annexaDashboardCounter', {
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-counter.html',
        controller:[function() {
            var vm = this;
        }],
        bindings: {
            number: '@'
        }
    })
    .component('annexaDashboardSign',{
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-sign.html',
        controller: ['GlobalDataFactory', 'Language', '$http', function(GlobalDataFactory, Language, $http) {
            var vm = this;

            vm.signModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.sign'");
            vm.signActionTypes = angular.copy(GlobalDataFactory.signActionTypes);
            vm.languageColumn = Language.getActiveColumn();

            _.forEach(vm.signActionTypes, function (item, index) {
                $http({
                    method: 'GET',
                    url: './api/signprocessactions/countPending/' + item.id
                }).then(function(data) {
                    vm.signActionTypes[index].num = data.data > 0 ? data.data : '';
                }).catch(function (error) {
                    console.error(error);
                });
            });

            $http({
                method: 'GET',
                url: './api/signprocessactions/countPending/urgent'
            }).then(function(data) {
                vm.numUrgents = data.data > 0 ? data.data : '';
            }).catch(function (error) {
                console.error(error);
            });
        }]
    });