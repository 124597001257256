angular
    .module('annexaApp')
    .component('annexaProfileEdit',{
        templateUrl: './components/common/annexa-profile-edit/annexa-profile-edit.html',
        controller: ['$rootScope', 'Language', 'RegFactory', function($rootScope, Language, RegFactory) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            if (!vm.title) {
                vm.title = 'global.literals.profile';
            }
            if (!vm.profiles) {
                vm.profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
            }

            vm.updateProfile = function(profile) {
                switch (vm.type) {
                    case 'registerEntry':
                        $rootScope.$broadcast('AnnexaProfileEditUpdated', { profile: profile });
                        break;
                }
            }

            this.$onInit = function () {
                if(vm.permissions && Array.isArray(vm.permissions) && vm.permissions.length == 0) {
                    vm.permissions.push('no_edit');
                }
                if(vm.profiles && vm.profiles.length != 0) {
                    vm.model =  $linq(vm.profiles).singleOrDefault(vm.model, "x => x.id == " + vm.model.id);
                }
            }
        }],
        bindings: {
            model: '=',
            type: '@',
            permissions: '=',
            disabled: '='
        }
    })