angular
    .module('annexaApp')
    .component('annexaAdvancedSearchModal',{
        templateUrl: './components/common/annexa-advanced-search-modal/annexa-advanced-search-modal.html',
        controller:['TableFilterFactory', '$rootScope', 'Language', '$scope', function (TableFilterFactory, $rootScope, Language, $scope) {
            var vm = this;

            vm.selectedRows = [];
            vm.rowSelected = {};
            vm.selectAll = false;
            
            vm.closeModal = function() {
                TableFilterFactory.clearFilters(vm.modalObject.filter)
                vm.modalObject.close();
            }

            vm.submitFilter = function () {
                vm.selectedRows = [];
                vm.rowSelected = {};
                vm.modalObject.tableDefinition.reloadInternalData(true);
            }

            vm.addSelected = function() {
                var selected = [];

                $rootScope.$broadcast('annexaAdvancedSearchModalAdd', { id: vm.id, selected: vm.selectedRows, modal: vm.modalObject });
            }
            
            vm.selectRow = function(id) {
                if(vm.modalObject.multiple) {
                    var indexSelected = $linq(vm.selectedRows).indexOf(" x => x.id == " + id);

                    if (indexSelected != -1) {
                        vm.selectedRows.splice(indexSelected, 1);
                    } else {
                    	if(vm.modalObject.tableDefinition.containerScope.tableObjects[id]){
                    		vm.selectedRows.push(vm.modalObject.tableDefinition.containerScope.tableObjects[id]);
                    	}
                    }
                    if(vm.selectedRows && vm.modalObject && vm.modalObject.tableDefinition && vm.modalObject.tableDefinition.containerScope && vm.modalObject.tableDefinition.containerScope.tableObjects && vm.selectedRows.length == Object.keys(vm.modalObject.tableDefinition.containerScope.tableObjects).length){
                    	vm.selectAll = true;
                    }else{
                    	vm.selectAll = false;
                    }
                } else {
                    vm.selectedRows = [];
                    if(vm.modalObject.tableDefinition.containerScope.tableObjects[id]){
                    	vm.selectedRows.push(vm.modalObject.tableDefinition.containerScope.tableObjects[id]);
                    }
                }
            }
            
            vm.selectAllRows = function() {
                if(vm.modalObject.multiple) {
            		vm.selectedRows.length = 0;
                	if(vm.selectAll){
                		_.forEach(vm.modalObject.tableDefinition.containerScope.tableObjects, function(object){
                			vm.selectedRows.push(object);
                			if(!vm.rowSelected){
                				vm.rowSelected = {};
                			}
                			vm.rowSelected[object.id] = true;
                		});
                	}else{
                		if(vm.rowSelected){
                			var keys = Object.keys(vm.rowSelected);
                            _.forEach(keys, function(key){
                            	vm.rowSelected[key] = false;
                            });
                		}
                	}
                }
            }
            
            this.$onInit = function () {
                if(!vm.modalObject.tableDefinition.filterCallFunc) {
                    vm.modalObject.tableDefinition.filterCallFunc = function () {
                        var filterCall = TableFilterFactory.getFilterCall(vm.modalObject.filter);

                        if(vm.modalObject.tableDefinition.callToAdd) {
                            _.forOwn(vm.modalObject.tableDefinition.callToAdd, function (value, key) {
                                filterCall[key] = value;
                            });
                        }

                        return filterCall;
                    }
                }

                if(!vm.modalObject.tableDefinition.filterCallAuxFunc) {
                    vm.modalObject.tableDefinition.filterCallAuxFunc = function () {
                        var filterCallAux = TableFilterFactory.getFilterCallAux(vm.modalObject.filter);
                        filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        filterCallAux.langColumn = Language.getActiveColumn();

                        if(vm.modalObject.tableDefinition.callAuxToAdd) {
                            _.forOwn(vm.modalObject.tableDefinition.callAuxToAdd, function (value, key) {
                                filterCallAux[key] = value;
                            });
                        }

                        return filterCallAux;
                    }
                }

                if(!vm.modalObject.tableDefinition.filterCall) {
                    vm.modalObject.tableDefinition.filterCall = vm.modalObject.tableDefinition.filterCallFunc();
                }

                if(!vm.modalObject.tableDefinition.filterCallAux) {
                    vm.modalObject.tableDefinition.filterCallAux = vm.modalObject.tableDefinition.filterCallAuxFunc();
                }

                if(!vm.modalObject.tableDefinition.containerScope) {
                    vm.modalObject.tableDefinition.containerScope = $scope;
                }

                vm.modalObject.tableDefinition.useTableObjects = true;

                var firstColumnRender = function (data, type, full, meta) {
                    var content = '';

                    //content += '<input type="' + (vm.modalObject.multiple ? 'checkbox' : 'radio') + '" id="' + data + '" name="selected" ng-model="$ctrl.rowSelected[' + data + ']" ng-change="$ctrl.selectRow(' + data + ')">';
                    if(vm.modalObject.multiple) {
                        content += '<input type="checkbox" id="' + data + '" name="selected" ng-model="$ctrl.rowSelected[' + data + ']" ng-change="$ctrl.selectRow(' + data + ')">';
                    } else {
                        content += '<input type="radio" id="' + data + '" name="selected" ng-model="$ctrl.rowSelected" ng-value="' + data + '" ng-change="$ctrl.selectRow(' + data + ')">';
                    }

                    return content;
                }

                var firstColumnTitle = function(a,b,c,d) {
                    var content = '';
                    if(vm.modalObject.multiple){
                    	content += '<input type="checkbox" id="annexaAdvancedSearchAddAllElements" name="annexaAdvancedSearchAddAllElements" ng-model="$ctrl.selectAll" ng-change="$ctrl.selectAllRows()">';
                    }
                    return content;
                }
                
                vm.modalObject.tableDefinition.columns.unshift({ id: 'select', columnName: 'id', title: firstColumnTitle(), render: firstColumnRender, sortable: false });
            }
        }],
        bindings: {
            id: '@',
            modalObject: '='
        }
    })