/**
 * Created by osirvent on 09/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaLinkValue', {
        transclude: true,
        templateUrl: './components/common/annexa-link-value/annexa-link-value.html',
        controller: [function () {
            var vm = this;

            this.$onInit = function () {
            	 if(!vm.showLabelAux) {
                     vm.showLabelAux = false;
                 }
            	if(!vm.label) {
                    vm.label = 'label';
                }
                if(!vm.labelAux) {
                    vm.labelAux = '';
                }
                if(!vm.link) {
                    vm.link = 'link';
                }
            };
        }],
        bindings: {
        	labelAux: '@',
            label: '@',
            link: '@',
            showLabelAux: '@?'
        }
    })