angular
    .module('annexaApp')
    .component('annexaFileUpload',{
        templateUrl: './components/common/annexa-file-upload/annexa-file-upload.html',
        controller:['$scope', '$element', '$attrs', '$rootScope', '$q', '$window', function ($scope, $element, $attrs, $rootScope, $q, $window) {
            var vm = this;

            var isolateScope = {
                onChange: '&',
                onAfterValidate: '&',
                parser: '&'
            };

            var FILE_READER_EVENTS = ['onabort', 'onerror', 'onloadstart', 'onloadend', 'onprogress', 'onload'];

            FILE_READER_EVENTS.forEach(function(e) {
                isolateScope[e] = '&';
            });

            var rawFiles = [];
            var fileObjects = [];

            //var ngModel = scope.form; AXO NO HO TENIM PERQUE NO ESTÀ EN UN FORMULARI

            //region Validations

            var _maxsize = function(val) {
                var valid = true;

                if($attrs.maxsize && val) {
                    var max = parseFloat($attrs.maxsize) * 1000;

                    if($attrs.multiple) {
                        for(var i = 0; i < val.length; i++) {
                            var file = val[i];
                            if(file.filesize > max) {
                                valid = false;
                                break;
                            }
                        }
                    } else {
                        valid = val.filesize <= max;
                    }
                    //ngModel.$setValidity('maxsize', valid); AXO NO HO TENIM PERQUE NO ESTÀ EN UN FORMULARI
                }

                return val;
            }

            var _minsize = function(val) {
                var valid = true;
                var min = parseFloat($attrs.minsize) * 1000;

                if ($attrs.minsize && val) {
                    if ($attrs.multiple) {
                        for (var i = 0; i < val.length; i++) {
                            var file = val[i];
                            if (file.filesize < min) {
                                valid = false;
                                break;
                            }
                        }
                    } else {
                        valid = val.filesize >= min;
                    }
                    //ngModel.$setValidity('minsize', valid); AXO NO HO TENIM PERQUE NO ESTÀ EN UN FORMULARI
                }

                return val;
            }

            var _maxnum = function(val) {
                if ($attrs.maxnum && $attrs.multiple && val) {
                    var valid = val.length <= parseInt($attrs.maxnum);
                    //ngModel.$setValidity('maxnum', valid); AXO NO HO TENIM PERQUE NO ESTÀ EN UN FORMULARI
                }

                return val;
            }

            function _minnum(val) {
                if ($attrs.minnum && $attrs.multiple && val) {
                    var valid = val.length >= parseInt($attrs.minnum);
                    //ngModel.$setValidity('minnum', valid); AXO NO HO TENIM PERQUE NO ESTÀ EN UN FORMULARI
                }

                return val;
            }

            function _accept(val) {
                var valid = true;
                var regExp, exp, fileExt;
                if ($attrs.accept) {
                    exp = $attrs.accept.trim().replace(/[,\s]+/gi, "|").replace(/\./g, "\\.").replace(/\/\*/g, "/.*");
                    regExp = new RegExp(exp);
                }

                if ($attrs.accept && val) {
                    if ($attrs.multiple) {
                        for (var i = 0; i < val.length; i++) {
                            var file = val[i];
                            fileExt = "." + file.filename.split('.').pop();
                            valid = regExp.test(file.filetype) || regExp.test(fileExt);

                            if (!valid) {
                                break;
                            }
                        }
                    } else {
                        fileExt = "." + val.filename.split('.').pop();
                        valid = regExp.test(val.filetype) || regExp.test(fileExt);
                    }
                    //ngModel.$setValidity('accept', valid); AXO NO HO TENIM PERQUE NO ESTÀ EN UN FORMULARI
                }

                return val;
            }

            //endregion

            var _setViewValue = function () {
                var newVal = $attrs.multiple ? fileObjects : fileObjects[0];
                //scope.fc.$setViewValue(newVal); AQUI POSTSER CAL ESTABLIR EL VALOR DE file
                _maxsize(newVal);
                _minsize(newVal);
                _maxnum(newVal);
                _minnum(newVal);
                _accept(newVal);

                if(!vm.changed) {
                    $rootScope.$broadcast('annexaFileUploadComponentChange', {files: newVal, origin: vm.origin});
                } else {
                    vm.changed(newVal);
                }
            }

            var _attachHandlerForEvent = function (eventName, handler, fReader, file, fileObject) {
                fReader[eventName] = function (e) {
                    handler()(e, fReader, file, rawFiles, fileObjects, fileObject);
                }
            }

            var _readerOnLoad = function (fReader, file, fileObject) {
                return function(e) {
                    var buffer = e.target.result;
                    var promise;

                    var exceedsMaxSize = $attrs.maxsize && file.size > $attrs.maxsize * 1024;
                    if($attrs.doNotParseIfOversize !== undefined && exceedsMaxSize) {
                        fileObject.base64 = null;
                    } else {
                        fileObject.base64 = $window._arrayBufferToBase64(buffer);
                    }

                    if($attrs.parser) {
                        promise = $q.when($scope.parser()(file, fileObject));
                    } else {
                        promise = $q.when(fileObject);
                    }

                    promise.then(function(fileObj) {
                        fileObjects.push(fileObj);
                        file.deferredObj.resolve();
                    });

                    if($attrs.onload) {
                        if($scope.onload && typeof $scope.onload() === "function") {
                            $scope.onload()(e, fReader, file, rawFiles, fileObjects, fileObject);
                        } else {
                            $scope.onload(e, rawFiles);
                        }
                    }
                }
            }

            var _attachEventHandlers = function (fReader, file, fileObject) {
                for(var i = FILE_READER_EVENTS.length - 1; i >= 0; i--) {
                    var e = FILE_READER_EVENTS[i];
                    if($attrs[e] && e !== 'onload') {
                        _attachHandlerForEvent(e, $scope[e], fReader, file, fileObject);
                    }
                }

                fReader.onload = _readerOnLoad(fReader, file, fileObject);
            }

            var _readFiles = function() {
                var promises = [];
                var i;

                for(i = rawFiles.length - 1; i >= 0; i--) {
                    rawFiles[i].deferredObj = $q.defer();
                    promises.push(rawFiles[i].deferredObj.promise);
                }

                $q.all(promises).then(_setViewValue);

                for(i = rawFiles.length - 1; i >= 0; i--) {
                    var reader = new $window.FileReader();
                    var file = rawFiles[i];
                    var fileObject = {};

                    fileObject.filetype = file.type;
                    fileObject.filename = file.name;
                    fileObject.filesize = file.size;

                    _attachEventHandlers(reader, file, fileObject);
                    reader.readAsArrayBuffer(file);
                }
            }

            var _onChange = function (e) {
                if($attrs.onChange) {
                    if($scope.onChange && typeof $scope.onChange() === "function") {
                        $scope.onChange()(e, rawFiles);
                    } else {
                        $scope.onChange(e, rawFiles);
                    }
                }
            }

            var _onAfterValidate = function(e) {
                if($attrs.onAfterValidate) {
                    var promises = [];

                    for(var i = rawFiles.length - 1; i >= 0; i--) {
                        promises.push(rawFiles[i].deferredObj.promise);
                    }

                    $q.all(promises).then(function() {
                        if($scope.onAfterValidate && typeof $scope.onAfterValidate() === "function") {
                            $scope.onAfterValidate()(e, fileObjects, rawFiles);
                        } else {
                            $scope.onAfterValidate(e, fileObjects, rawFiles);
                        }
                    });
                }
            }

            /*ngModel.$isEmpty = function(val) {
                return !val || (angular.isArray(val) ? val.length === 0 : !val.base64);
            };*/

            vm._clearInput = function () {
                $element[0].value = '';
            }

            this.$onInit = function() {
                $element.on('change', function (e) {
                    $attrs.multiple = e.target.multiple;

                    fileObjects = [];
                    fileObjects = angular.copy(fileObjects);

                    if(e.target.files.lenght === 0) {
                        rawFiles = [];
                        _setViewValue();
                    } else {
                        rawFiles = e.target.files;
                        _readFiles();
                        _onChange(e);
                        _onAfterValidate(e);
                    }

                    vm._clearInput();

                    $rootScope.$broadcast('annexaFileUploadComponentChanged', {origin: vm.origin})
                });
            }
        }],
        bindings: {
            colClass: '@?',
            icon: '@?',
            label: '@',
            file: '=',
            origin: '@',
            accept: '@?',
            changed: '=?'
        }
    });