angular
    .module('annexaApp')
    .component('annexaSelectDocuments', {
        templateUrl: './components/common/annexa-select-documents/annexa-select-documents.html',
        controller: [function() {
            var vm = this;

            if(!vm.title) {
                vm.title = 'global.literals.documents'
            }

            _.forEach(vm.documents, function (value, key) {
            	vm.documents[key].selected = false;
            	
            	if (vm.documentsIdSelected) {
            		var documentSelected = $linq(vm.documentsIdSelected).where("x => x == " + vm.documents[key].id).toArray();
            		if (documentSelected.length > 0) {
            			vm.documents[key].selected = true;
            		}
            	}
            });

            vm.selectDocument = function ($event, index) {
                var checkbox = $event.target;

                vm.documents[index].selected = checkbox.checked;
            }
        }],
        bindings: {
            title: '@?',
            documents: '=',
            documentsIdSelected: '='
        }
    })