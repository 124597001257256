angular
    .module('annexaApp')
    .component('annexaHrefPermissionRegInput',{
        templateUrl: './components/common/annexa-href-permission/annexa-href-permission-reg-input.html',
        controller:['$state', '$rootScope', 'AnnexaPermissionsFactory', function ($state, $rootScope, AnnexaPermissionsFactory) {
            var vm = this;

            vm.text = '';
            vm.diligenceProfile = undefined;

            vm.canShow = function () {
                if(vm.registerEntry.entryType == 'OUTPUT') {
                    return (AnnexaPermissionsFactory.havePermission('view_output_register', true) || AnnexaPermissionsFactory.havePermission('view_all_output_register', true));
                }

                if(!AnnexaPermissionsFactory.havePermission('view_input_register', true) && !AnnexaPermissionsFactory.havePermission('view_all_input_register', true)) {
                    var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                    var registerProfiles = $linq(vm.registerEntry.entryDiligences).select(function (x) {
                        if (x.diligenceProfile && x.diligenceProfile.id) {
                            return x.diligenceProfile.id;
                        }
                    }).toArray();

                    var intersect = $linq(userProfiles).intersect(registerProfiles, "(x,y) => x == y").toArray();

                    vm.diligenceProfile = intersect.length > 0 ? intersect[0] : undefined;

                    return intersect.length > 0;
                }

                return true;
            }

            var getRegInputUrl = function() {
                if(!vm.diligenceProfile) {
                    return $state.href('annexa.reg.input.entries.edit', { input: vm.registerEntry.id })
                } else {
                    var diligence = $linq(vm.registerEntry.entryDiligences).firstOrDefault(undefined, "x => x.diligenceProfile.id == " + vm.diligenceProfile);

                    if(diligence) {
                        return $state.href('annexa.reg.input.diligences.edit', { input: vm.registerEntry.id, diligence: diligence.id });
                    }

                    return '.';
                }
            }


            vm.redirect = function () {
                var url = vm.registerEntry.entryType == 'INPUT' ?
                    getRegInputUrl() :
                    $state.href('annexa.reg.output.entries.edit', { output: vm.registerEntry.id });

                window.open(url, '_blank');
            }

            this.$onInit = function () {
                if(vm.registerEntry.originEntryNumber) {
                    vm.text = vm.registerEntry.originEntryNumber;
                } else {
                    vm.text = vm.registerEntry.entryNumber;
                }
            }
        }],
        bindings: {
            registerEntry: '='
        }
    })
    .component('annexaHrefPermissionDocument',{
        templateUrl: './components/common/annexa-href-permission/annexa-href-permission-document.html',
        controller:['AnnexaPermissionsFactory', '$state', '$rootScope', function(AnnexaPermissionsFactory, $state, $rootScope) {
            var vm = this;

            vm.text = '';

            vm.canShow = function () {
                if(AnnexaPermissionsFactory.havePermission('view_documents')) {
                    if (vm.origin && _.contains(['THIRD', 'DILIGENCE', 'DOSSIER', 'TASK', 'NOTIFICATION', 'SEC'], vm.origin)) {
                        return true;
                    }
                    if($state.current && $state.current.name && ($state.current.name == 'annexa.reg.input.entries.edit' || $state.current.name == 'annexa.reg.input.entries.new' || $state.current.name == 'annexa.reg.output.entries.new' || $state.current.name == 'annexa.reg.output.entries.edit') && AnnexaPermissionsFactory.havePermission('view_register_entry_documents')){
                        return true;
                    }
                    if(vm.document && vm.document.profiles) {
                        var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                        var documentProfiles = $linq(vm.document.profiles).select("x => x.profile.id").toArray();
                        var intersect = $linq(userProfiles).intersect(documentProfiles, "(x,y) => x == y").toArray();
                        return intersect.length > 0;
                    }
                }

                return false;
            }

            vm.redirect = function () {
                window.open($state.href('annexa.doc.documents.view', { code: vm.document.id }), '_blank');
            }

            this.$onInit = function () {
                var properties = Array.isArray(vm.nameProperties) ? vm.nameProperties : [vm.nameProperties];

                _.forEach(properties, function(item) {
                    if(vm.text != '' && vm.document[item]) {
                        vm.text += ' ';
                    }

                    vm.text += vm.document[item]
                });
            }
        }],
        bindings: {
            document: '=',
            nameProperties: '=',
            origin: '@?'
        }
    })
    .component('annexaHrefPermissionDossier',{
        templateUrl: './components/common/annexa-href-permission/annexa-href-permission-dossier.html',
        controller:['AnnexaPermissionsFactory', '$state', '$rootScope', function(AnnexaPermissionsFactory, $state, $rootScope) {
            var vm = this;

            vm.text = '';

            vm.canShow = function () {
                return vm.canView() || vm.canProcess();
            }

            vm.canProcess = function () {
                var haveProcessProfile = false;

                if(AnnexaPermissionsFactory.havePermission('process_dossier')) {
                    var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                    var dossierProcessProfiles = [];
                    if(vm.dossier && vm.dossier.dossierTransactions) {
                        _.forEach(vm.dossier.dossierTransactions, function (dt) {
                            if(dt.processProfiles) {
                                _.forEach(dt.processProfiles, function (item) {
                                    dossierProcessProfiles.push(item.profile.id);
                                })
                            }
                        });
                    }
                    var intersect = $linq(userProfiles).intersect(dossierProcessProfiles, "(x,y) => x == y").toArray();

                    haveProcessProfile = intersect.length > 0;

                }

                return haveProcessProfile;
            }

            vm.getDossierTransaction = function () {
                var dossierTransaction;
                if(AnnexaPermissionsFactory.havePermission('process_dossier')) {
                    var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                    if(vm.dossier && vm.dossier.dossierTransactions) {
                        _.forEach(vm.dossier.dossierTransactions, function (dt) {
                            if (dt.actual && dt.processProfiles) {
                                var dossierProcessProfiles = [];
                                _.forEach(dt.processProfiles, function (item) {
                                    dossierProcessProfiles.push(item.profile.id);
                                })
                                var intersect = $linq(userProfiles).intersect(dossierProcessProfiles, "(x,y) => x == y").toArray();
                                if (intersect.length > 0) {
                                    dossierTransaction = dt;
                                }
                            }
                        });
                    }
                }

                return dossierTransaction;
            }

            vm.canView = function () {
                var haveViewProfile = false;

                if(AnnexaPermissionsFactory.havePermission('view_dossier')) {
                    var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                    if(vm.dossier && vm.dossier.procedure && vm.dossier.procedure.procedureViewProfiles) {
                        var dossierViewProfiles = $linq(vm.dossier.procedure.procedureViewProfiles).select("x => x.profile.id").toArray();

                        var intersect = $linq(userProfiles).intersect(dossierViewProfiles, "(x,y) => x == y").toArray();

                        haveViewProfile = intersect.length > 0;
                    }
                }

                return haveViewProfile;
            }

            vm.redirect = function () {
                if(vm.canProcess()){
                    var dossierTransaction = vm.getDossierTransaction();
                    if(dossierTransaction) {
                        window.open($state.href('annexa.tram.pending.viewdossier', {dossier: vm.dossier.id, dossierTransaction: dossierTransaction.id}), '_blank');
                    }else{
                        window.open($state.href('annexa.tram.view.viewdossier', { dossier: vm.dossier.id, dossierTransaction: -1 }), '_blank');
                    }
                }else{
                    window.open($state.href('annexa.tram.view.viewdossier', { dossier: vm.dossier.id, dossierTransaction: -1 }), '_blank');
                }
            }

            this.$onInit = function() {
                vm.text = vm.dossier ? vm.dossier.dossierNumber : '';
            }
        }],
        bindings: {
            dossier: '='
        }
    })
    .component('annexaHrefPermissionDossierTransaction',{
        templateUrl: './components/common/annexa-href-permission/annexa-href-permission-dossier-transaction.html',
        controller:['AnnexaPermissionsFactory', '$state', '$rootScope', function(AnnexaPermissionsFactory, $state, $rootScope) {
            var vm = this;

            vm.text = '';

            vm.canShow = function () {
                return canViewDossier() || canProcessDossier();
            }

            var canViewDossier = function(){
                var haveViewProfile = false;
                var haveGlobalProcessProfile = false;

                if(AnnexaPermissionsFactory.havePermission('view_dossier')) {
                    var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                    var dossierTransactionViewProfiles = [];
                    if(vm.dossierTransaction && vm.dossierTransaction.dossier && vm.dossierTransaction.dossier.procedure && vm.dossierTransaction.dossier.procedure.procedureViewProfiles ){
                        dossierTransactionViewProfiles = $linq(vm.dossierTransaction.dossier.procedure.procedureViewProfiles).select("x => x.profile.id").toArray();
                    }

                    var intersect = $linq(userProfiles).intersect(dossierTransactionViewProfiles, "(x,y) => x == y").toArray();

                    haveViewProfile = intersect.length > 0;
                }

                if(AnnexaPermissionsFactory.havePermission('process_dossier')) {
                    var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();

                    if(vm.dossierTransaction && vm.dossierTransaction.globalProcessProfileIds) {
                        var intersect = $linq(userProfiles).intersect(vm.dossierTransaction.globalProcessProfileIds, "(x,y) => x == y").toArray();
                        haveGlobalProcessProfile = intersect.length > 0;
                    }
                }

                return haveViewProfile || haveGlobalProcessProfile;
            }

            var canProcessDossier = function(){
                var haveProcessProfile = false;

                if(AnnexaPermissionsFactory.havePermission('process_dossier')) {
                    var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                    var dossierTransactionProcessProfiles = [];
                    if(vm.dossierTransaction && vm.dossierTransaction.processProfiles ){
                        _.forEach(vm.dossierTransaction.processProfiles, function (item) {
                            dossierTransactionProcessProfiles.push(item.profile.id);
                        })
                    }
                    var intersect = $linq(userProfiles).intersect(dossierTransactionProcessProfiles, "(x,y) => x == y").toArray();

                    haveProcessProfile = intersect.length > 0;
                }

                return haveProcessProfile;
            }

            vm.redirect = function () {
                if(canProcessDossier()) {
                    window.open($state.href('annexa.tram.pending.viewdossier', {
                        dossier: vm.dossierTransaction.dossier.id,
                        dossierTransaction: vm.dossierTransaction.id
                    }), '_blank');
                }else{
                    window.open($state.href('annexa.tram.view.viewdossier', {
                        dossier: vm.dossierTransaction.dossier.id,
                        dossierTransaction: vm.dossierTransaction.id
                    }), '_blank');
                }
            }

            this.$onInit = function() {
                vm.text = vm.dossierTransaction.dossier.dossierNumber;
            }
        }],
        bindings: {
            dossierTransaction: '='
        }
    })