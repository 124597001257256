angular
    .module('annexaApp')
    .component('tableEditable', {
        templateUrl: './components/common/table-editable/table-editable.html',
        controller:['$filter', 'CommonService', '$rootScope', function($filter, CommonService, $rootScope) {
            var vm = this;

            vm.checkRequired = function($data, column) {
                if(column.required && !$data) {
                    return $filter('translate')('global.validation.required');
                }
            }

            vm.printUser = function(user) {
                if(user.value && user.user) {
                    return user.value;
                } else {
                    return 'global.documents.modal.sendToSign.empty';
                }
            }

            vm.loadUsers = CommonService.loadUsers;

            vm.inserted = {};

            vm.addItem = function() {
                vm.inserted = vm.newItem();

                vm.items.push(vm.inserted);
            }

            vm.columnsWithoutActions = function () {
                return $linq(vm.columns).where("x => x.type != 'actions'").toArray();
            }
        }],
        bindings: {
            columns: '=',
            items: '=',
            orderBy: '@',
            update: '=',
            newItem: '=',
            removeItem: '='
        }
    })